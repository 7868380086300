




















































































































import mixins from 'vue-typed-mixins'
import { DELETE_WAVE, LIST_WAVES } from '../graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils/text'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { Query, Run, Wave } from '@simpl/core/types/graphql'
import { DataTableHeader } from '@simpl/core/types/table'
import EditWaveDialog from '../components/EditWaveDialog.vue'

export type WaveExtended = Wave & { name: string }

export default mixins(StringToColor,
                      UserSettings('waveList', [
                        'sortBy',
                        'sortDesc',
                        'selectedView',
                        'itemsPerPage'
                      ])).extend({
  name: 'WavesListView',

  components: {
    EditWaveDialog
  },

  props: {
    run: Object as () => Run
  },

  data: () => ({
    /** Displayed data */
    waves: null! as WaveExtended[],
    loading: 0,

    /** Dialog control flow */
    dialog: false,
    selected: null! as WaveExtended,
    editing: false,

    /** Search and filter */
    searchValue: '',
    sortBy: [] as any[],
    sortDesc: [] as any[],
    count: 1,
    page: 1,
    itemsPerPage: 10,
    showFilter: false
  }),

  computed: {
    headers (): DataTableHeader[] {
      return [{
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('wave.global.startsAt'),
        value: 'starts_at'
      }, {
        text: this.$t('wave.global.endsAt'),
        value: 'ends_at'
      }, {
        text: this.$t('wave.global.participantMax'),
        value: 'participant_max'
      }, {
        text: this.$t('wave.global.participantCount'),
        value: 'participant_count'
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 120,
        sortable: false
      }]
    },
    presentedData (): WaveExtended[] {
      return this.waves?.slice()
    },
    computedHeaders (): DataTableHeader[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    waveDates (): string[] {
      return this.waves?.map((key) => { return key.starts_at }) || []
    }
  },

  watch: {
    // Reset page, when searchValue has changed
    searchValue () {
      this.page = 1
    }
  },

  apollo: {
    waves: {
      query: LIST_WAVES,

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['texts:display_name']
            },
            filterBy: [{
              name: 'run_id',
              values: [this.run.id]
            }]
          },
          orderBy: this.sortBy.map((key, index) => ({
            column: this.sortBy[index],
            order: this.sortDesc[index] ? 'DESC' : 'ASC'
          })),
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): WaveExtended[] {
        const { total, currentPage, perPage } = result!.waves!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage
        return result.waves!.data.map(this.remapEntry)
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    updateSearchValue (v: string) {
      this.searchValue = v
    },

    remapEntry (entry: Wave): WaveExtended {
      let name = getTextForTemporaryUserLanguage(entry)
      if (name === 'display_name') {
        name = entry.identifier
      }
      return {
        ...entry,
        name: name
      }
    },

    add () {
      this.selected = null!
      this.editing = false
      this.dialog = true
    },
    edit (item: WaveExtended) {
      this.selected = item
      this.editing = true
      this.dialog = true
    },
    async remove (id: string, name: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('core.message.deleteConfirm', [name]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return
      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_WAVE,
        variables: {
          id
        }
      })
      this.loading -= 1
      await this.$apollo.queries.waves.refetch()
      this.$emit('updated')
    },

    async refetch () {
      await this.$apollo.queries.waves.refetch()
      this.$emit('updated')
    }
  }
})
