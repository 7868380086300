








































































































import mixins from 'vue-typed-mixins'
import { createTextWithFallback, nameToIdentifier } from '@simpl/core/utils/text'
import { CREATE_WAVE, UPDATE_WAVE } from '../graphql'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ValidationRules from '@simpl/core/mixins/utils/ValidationRules'
import { Wave, UpdateWaveInput } from '@simpl/core/types/graphql'
import { unsavedChanges } from '@simpl/core/utils/message'
import { objectHash } from '@simpl/core/utils'
import { getLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'

export type WaveExtended = Wave & { name: string }
export type WaveDialogInput = {
  name: string,
  /* eslint-disable camelcase */
  starts_at: string,
  ends_at: string,
  participant_count: number,
  participant_max: number
}

const emptyInput = () => ({
  name: '',
  starts_at: '',
  ends_at: '',
  participant_count: 0
} as WaveDialogInput)

export default mixins(StringToColor, ValidationRules).extend({
  name: 'EditWaveDialog',

  props: {
    value: Boolean,
    selectedWave: Object as () => WaveExtended,
    runId: [String, Number],
    waveDates: Array
  },

  data () {
    return {
      /** Displayed data **/
      loading: false,

      /** Input data **/
      input: {} as WaveDialogInput,

      /** Input control **/
      initialHash: null! as string,
      currentHash: null! as string,
      participantMaxSet: false
    }
  },

  computed: {
    participantMax (): number {
      const max = this.input.participant_max
      return (this.participantMaxSet)
        ? (typeof max === 'string' ? parseInt(max, 10) : max)
        : -1
    },
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    dateRange: {
      get (): string[] {
        if (!this.input.starts_at && !this.input.ends_at) return []
        const range = []
        if (this.input.starts_at) range.push(this.input.starts_at)
        if (this.input.ends_at) range.push(this.input.ends_at)

        return range
      },
      set (v: string[]) {
        this.input.starts_at = Date.parse(v[0]) < Date.parse(v[1]) ? v[0] : v[1] || null!
        this.input.ends_at = Date.parse(v[0]) < Date.parse(v[1]) ? v[1] : v[0] || null!
      }
    },
    dateRangeFooter (): string {
      if (!this.dateRange || !this.dateRange.length) return '-'
      if (this.dateRange.length === 1) return `${getLocaleDateTimeFormat(this.dateRange[0])} - `
      return `${getLocaleDateTimeFormat(this.dateRange[0])} - ${getLocaleDateTimeFormat(this.dateRange[1])}`
    },
    hasChanged (): boolean {
      return this.initialHash !== this.currentHash
    },
    canSave (): boolean {
      return !!this.input.ends_at && this.hasChanged
    }
  },

  watch: {
    input: {
      deep: true,
      handler (v) {
        this.currentHash = objectHash({ ...v, participantMaxSet: this.participantMaxSet })
      }
    },
    participantMaxSet (v) {
      this.currentHash = objectHash({ ...this.input, participantMaxSet: v })
    },
    async show (v) {
      if (!v) return

      if (this.selectedWave) {
        this.participantMaxSet = this.selectedWave.participant_max > -1
        this.initialHash = objectHash(
          { ...this.selectedWave, participantMaxSet: this.participantMaxSet }
        )
        this.input = { ...this.selectedWave }
        this.dateRange[0] = this.input.starts_at
        this.dateRange[1] = this.input.ends_at
      } else {
        this.dateRange = []
        this.input = emptyInput()
        this.input.participant_max = this.input.participant_max === undefined
          ? 30
          : this.input.participant_max
        this.initialHash = objectHash(
          { ...this.input, participantMaxSet: this.participantMaxSet }
        )
      }
    }
  },

  methods: {
    getWaveMutationData () {
      const data = {
        run: {
          connect: this.runId
        },
        identifier: nameToIdentifier(this.input.name),
        starts_at: this.input.starts_at,
        ends_at: this.input.ends_at,
        participant_count: this.input.participant_count,
        participant_max: this.participantMax
      } as UpdateWaveInput

      if (!data.starts_at) data.starts_at = data.ends_at

      if (this.input.name === '') {
        data.identifier = data.starts_at
      } else {
        data.identifier = nameToIdentifier(this.input.name)
        data.texts = {
          create: [
            ...createTextWithFallback(this.input.name)
          ]
        }
      }

      if (this.selectedWave) data.id = this.selectedWave.id

      return { data }
    },
    async close () {
      this.show = this.hasChanged ? await unsavedChanges(this) : false
    },
    async save () {
      this.loading = true

      await this.$apollo.mutate({
        mutation: this.selectedWave ? UPDATE_WAVE : CREATE_WAVE,
        variables: this.getWaveMutationData()
      })

      this.$notification.publish('bottom', {
        message: this.$t(`wave.message.${this.selectedWave ? 'edited' : 'created'}`),
        type: 'success',
        color: 'success'
      })

      this.loading = false
      this.show = false
      this.$emit('saved')
    }
  }
})
