import gql from 'graphql-tag'

export const LIST_WAVES = gql`query LIST_WAVES (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    waves (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            identifier
            starts_at
            ends_at
            participant_count
            participant_max
            texts {
                identifier
                languagecode
                text
            }
        },
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const CREATE_WAVE = gql`mutation createWave ($data: NewWaveInput!) {
    createWave(data: $data) {
        id
    }
}`

export const UPDATE_WAVE = gql`mutation updateWave ($data: UpdateWaveInput!) {
    updateWave(data: $data) {
        id
    }
}`

export const DELETE_WAVE = gql`mutation DELETE_WAVE ($id: ID!) {
    deleteWave(id: $id) { id }
}`
