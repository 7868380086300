var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height content-padding"},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('wave.global.headline'))+" ")]),((_vm.waves && !_vm.waves.length) && !_vm.loading)?_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("wave.global.noWaveHint"))+" ")]):_vm._e(),_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('wave-create'),expression:"'wave-create'"}],staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.add}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('wave.action.createWave')))])])]},proxy:true}])}),_c('v-data-table',{attrs:{"loading":_vm.loading > 0,"headers":_vm.computedHeaders,"items":_vm.presentedData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":""},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.starts_at",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"locale-date-format",rawName:"v-locale-date-format",value:(item.starts_at),expression:"item.starts_at"}]})]}},{key:"item.ends_at",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"locale-date-format",rawName:"v-locale-date-format",value:(item.ends_at),expression:"item.ends_at"}]})]}},{key:"item.participant_max",fn:function(ref){
var item = ref.item;
return [(item.participant_max === -1)?_c('span',[_vm._v("∞")]):_c('span',[_vm._v(" "+_vm._s(item.participant_max)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('wave-create'),expression:"'wave-create'"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('wave.action.editWave')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('wave.action.deleteWave')))])])],1)]}}])}),_c('EditWaveDialog',{attrs:{"selected-wave":_vm.selected,"run-id":_vm.run.id,"starts-at":_vm.run.starts_at,"ends-at":_vm.run.ends_at,"wave-dates":_vm.waveDates},on:{"saved":_vm.refetch},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }